import { Side } from "@radix-ui/popper";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import { classNames } from "./utils/classNames";
import { PRIVACY_CLASSNAMES } from "@/lib/analytics";

export interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  disabled?: boolean;
  side?: Side;
  delayDuration?: number;
  arrowOffset?: number;
  sideOffset?: number;
  textSize?: 12 | 14; // Default is 12
  keepOpenOnActivation?: boolean;
}

export const Tooltip = (props: TooltipProps) => {
  if (props.disabled) {
    return <>{props.children}</>;
  }

  const textSize = props.textSize === 14 ? "text-14-20" : "text-12-16";
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={props.delayDuration ?? 500}>
        <RadixTooltip.Trigger
          asChild
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            // https://github.com/radix-ui/primitives/blob/main/packages/react/tooltip/src/Tooltip.stories.tsx#L659
            if (props.keepOpenOnActivation) {
              event.preventDefault();
            }
          }}
        >
          {props.children}
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            side={props.side}
            sideOffset={props.sideOffset ?? 4}
            className={classNames(
              // Antd drawer is z-[1000], and our modals are z-[1001], entity labels are 1010.
              // Popover should be at the very top.
              `pointer-events-none relative z-[1050] max-w-sm break-words rounded bg-oldgray-900 px-8 py-[6px] ${textSize} text-white `,
              "radix-side-top:animate-slide-down-fade",
              "radix-side-right:animate-slide-left-fade",
              "radix-side-bottom:animate-slide-up-fade",
              "radix-side-left:animate-slide-right-fade",
            )}
            collisionPadding={4}
            onPointerDownOutside={(event) => {
              if (props.keepOpenOnActivation) {
                event.preventDefault();
              }
            }}
          >
            {props.content}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

interface ConditionalTooltipProps extends TooltipProps {
  condition: boolean;
}

export const ConditionalTooltip = ({ condition, children, ...props }: ConditionalTooltipProps) =>
  condition ? <Tooltip {...props}>{children}</Tooltip> : children;

export const CONTACT_ADMIN_TOOLTIP = "Please contact your admin";

export default Tooltip;
